<template>
  <div class="update-trade-in">
    <b-row>
      <b-col cols='12'>
        <KTCodePreview v-bind:title="'Tạo phiếu thu cũ'">
          <template v-slot:preview>
            <v-form
              ref="form"
              lazy-validation
            >
              <b-col class="col-md-12 mb-5">
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row required-control">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >Chương trình:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="tradeInProgramSuggest.searchTradeInProgram"
                          :suggestions="tradeInProgramSuggest.filteredTradeInProgram"
                          :placeholder="'tên chương trình'"
                          :limit="10"
                          @select="tradeInProgramOnSelect"
                          @change="tradeInProgramOnSearchChange"
                        />
                        <small
                          v-if="missing.tradeInProgramId"
                          class="missing-mess d-block mt-2 text-danger"
                        >Vui lòng chọn chương trình thu cũ</small>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Người tạo:</label>
                      <span class="ml-4">{{ createdBy }}</span>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">

                    <div class="row required-control">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >Sản phẩm:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="productSuggest.searchProduct"
                          :suggestions="productSuggest.filteredProductOptions"
                          @change="productOnSearchChange"
                          @select="productOnSelect"
                          size="sm"
                          suggestionName="suggestionName"
                          placeholder="tên sp"
                        />
                        <small
                          v-if="missing.productId"
                          class="missing-mess d-block mt-2 text-danger"
                        >Vui lòng chọn sản phẩm</small>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row required-control">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Cửa hàng:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="storeName"
                          :suggestions="storeFilteredOptions"
                          placeholder="tên cửa hàng"
                          @select="onSelected"
                          @change="onInputChangeStore"
                          :disabled="!!employeeInfo.storeId"
                        />
                        <small
                          v-if="missing.storeId"
                          class="missing-mess d-block mt-2 text-danger"
                        >Vui lòng chọn cửa hàng</small>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row required-control">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >Sđt khách hàng:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="customerSuggest.searchCustomer"
                          :suggestions="customerSuggest.filteredCustomerOptions"
                          @change="customerOnSearchChange"
                          @select="customerOnSelect"
                          aria-describedby="input-imei-model-code"
                          size="sm"
                          suggestionName="suggestionName"
                          placeholder="số điện thoại của khách hàng"
                        />
                        <small
                          v-if="missing.customer.phoneNo"
                          class="missing-mess d-block mt-2 text-danger"
                        >Vui lòng nhập đúng sđt hoặc đúng định dạng sđt </small>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Trạng thái:</label>
                      <b-col md="6">
                        <b-form-select
                          class="select-style"
                          size="sm"
                          id="input-3"
                          v-model="selectedStatus"
                          :options="listStatus"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row required-control">
                      <label
                        for="input-status"
                        class="col-md-3 pl-0 font-weight-bolder"
                      >Họ tên KH:</label>
                      <b-col md="6">
                        <b-form-input
                          size="sm"
                          type="text"
                          v-model="payload.customer.fullName"
                          placeholder="Nhập họ tên khách hàng"
                          aria-describedby="input-live-help input-1-live-feedback"
                          :disabled='customerSuggest.disableNameField'
                        ></b-form-input>
                        <small
                          v-if="missing.customer.fullName"
                          class="missing-mess d-block mt-2 text-danger"
                        >Tên khách hàng không được để trống</small>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row required-control">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Loại:</label>
                      <b-col md="6">
                        <b-form-select
                          class="select-style"
                          size="sm"
                          id="input-3"
                          v-model="payload.typeTradeIn"
                          :options="listType"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                        <small
                          v-if="missing.typeTradeIn"
                          class="missing-mess d-block mt-2 text-danger"
                        >Vui lòng chọn loại thu cũ / đổi mới</small>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-6"></div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-4">

                    <div class="row mt-4 required-control">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-5 pl-0"
                      >Giá trên sản phẩm:</label>
                      <div class="col-md-7 pr-0">
                        <b-form-input
                          size="sm"
                          v-model="payload.productBuyingPrice"
                          placeholder="Nhập giá trên sản phẩm"
                          v-mask="currencyMask"
                        ></b-form-input>
                        <small
                          v-if="missing.productBuyingPrice"
                          class="missing-mess d-block mt-2 text-danger"
                        >Vui lòng nhập giá trên sản phẩm</small>
                      </div>
                    </div>
                    <div class="row mt-4 required-control">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-5 pl-0"
                      >Giá thu cuối cùng:</label>
                      <div class="col-md-7 pr-0">
                        <b-form-input
                          size="sm"
                          v-model="payload.finalBuyingPrice"
                          placeholder="Nhập giá thu cuối cùng"
                          v-mask="currencyMask"
                        ></b-form-input>
                        <small
                          v-if="missing.finalBuyingPrice"
                          class="missing-mess d-block mt-2 text-danger"
                        >Vui lòng nhập giá thu cuối cùng</small>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-description"
                      >Ghi chú:</label>
                    </div>
                    <div class="row">
                      <b-form-textarea
                        id="textarea-description"
                        v-model="payload.note"
                        placeholder="Ghi chú"
                        rows="5"
                      ></b-form-textarea>
                    </div>
                    <div
                      class="row"
                      style="padding-left: 0px"
                    >
                      <div class="mb-5 mt-5">
                        <button
                          type="button"
                          class="
                            btn btn-primary
                            font-weight-bolder
                            btn-sm
                            button
                            mr-4
                          "
                          @click="onSubmit"
                          :disabled='isLoading'
                        >
                          Tạo phiếu
                          <div
                            v-if="isLoading"
                            class="d-inline-block pl-2"
                          >
                            <b-spinner
                              variant="light"
                              label="Spinning"
                              small
                            ></b-spinner>
                          </div>
                        </button>
                        <button
                          type="button"
                          class="
                            btn btn-primary
                            font-weight-bolder
                            btn-sm
                            button
                          "
                          @click="importTradeIn"
                          :disabled='isLoading'
                        >
                          Tạo phiếu & tạo phiếu nhập máy cũ
                          <div
                            v-if="isLoading"
                            class="d-inline-block pl-2"
                          >
                            <b-spinner
                              variant="light"
                              label="Spinning"
                              small
                            ></b-spinner>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </v-form>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { TIME_TRIGGER } from '@/utils/constants';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import {
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';
// import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import debounce from 'debounce';
import { PRODUCT_TYPE } from '@/utils/enum';
import { isValidPhoneNumber } from '@/utils/validation';

const employeeInfo = JSON.parse(localStorage.getItem('employee_info'));

export default {
  mixins: [validationMixin],
  data() {
    return {
      selectedStatus: 1,
      listStatus: [
        { id: 1, name: 'Đã báo giá' },
        // Tạm ẩn tính năng, có thể mở lại sau
        // { id: 2, name: 'Đã thu cũ' },
      ],
      listType: [
        {
          id: 1,
          name: 'Thu cũ',
        },
        {
          id: 2,
          name: 'Đổi mới',
        },
      ],
      filteredOptionsStore: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      newTradeInId: null,
      isLoading: false,
      employeeInfo,
      currencyMask,
      createdBy: employeeInfo.fullName,
      storeName: '',
      payload: {
        storeId: employeeInfo.storeId,
        tradeInProgramId: null,
        productId: null,
        customer: {
          fullName: '',
          phoneNo: '',
        },
        productBuyingPrice: '',
        finalBuyingPrice: '',
        typeTradeIn: 1,
        note: '',
      },
      numOfPayLoadProperty: 0,
      numOfValidField: 0,
      missing: {
        storeId: false,
        tradeInProgramId: false,
        productId: false,
        customer: {
          fullName: false,
          phoneNo: false,
        },
        productBuyingPrice: false,
        finalBuyingPrice: false,
        typeTradeIn: false,
        note: false,
      },

      tradeInProgramSuggest: {
        searchTradeInProgram: '',
        tradeInProgramOptions: [],
        filteredTradeInProgram: [],
      },
      productSuggest: {
        searchProduct: '',
        productOptions: [],
        filteredProductOptions: [],
      },
      customerSuggest: {
        searchCustomer: '',
        customerOptions: [],
        filteredCustomerOptions: [],
        disableNameField: true,
      },
      storeFilteredOptions: [],
      //
    };
  },
  validations: {
    form: {},
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thu cũ- đổi mới', route: '/trade-in' },
      { title: 'Danh sách phiếu thu cũ', route: '/trade-in' },
      { title: 'Tạo phiếu thu cũ' },
    ]);
  },
  computed: {
    ...mapGetters(['getOrderInfo']),
  },
  methods: {
    // ...getToastInstance(),

    //
    importTradeIn: async function () {
      await this.onSubmit();
      const { href } = this.$router.resolve({
        name: 'import-trade-in',
        query: { id: this.newTradeInId },
      });
      window.open(href, '_blank');
    },
    // search trade in program
    tradeInProgramOnSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.payload.tradeInProgramId = null;
      this.tradeInProgramSuggest.searchTradeInProgram = searchQuery;
      this.tradeInProgramSuggest.tradeInProgramOptions = [];
      this.tradeInProgramSuggest.filteredTradeInProgram = [
        ...this.tradeInProgramSuggest.tradeInProgramOptions,
      ];
      this.debounceTradeInProgramInput();
    },
    tradeInProgramOnSelect(option) {
      this.payload.tradeInProgramId = option.item.id;
    },
    getListTradeInProgram() {
      const text = this.tradeInProgramSuggest.searchTradeInProgram.trim();
      const param = {
        name: text,
      };
      const paramQuery = {
        params: param,
      };
      if (!text) {
        return;
      }

      ApiService.query('tradeInProgram', paramQuery).then((response) => {
        const result = response.data.data.data;
        this.tradeInProgramSuggest.tradeInProgramOptions = [];
        result.forEach((item) => {
          item.productCode = item.productCode ? item.productCode : '';
          const suggestionName = item.name;

          const program = {
            id: item.id,
            name: item.name,
            suggestionName,
          };
          this.tradeInProgramSuggest.tradeInProgramOptions.push(program);
        });
        this.tradeInProgramSuggest.filteredTradeInProgram = [
          ...this.tradeInProgramSuggest.tradeInProgramOptions,
        ];
      });
    },
    debounceTradeInProgramInput: debounce(function () {
      this.getListTradeInProgram();
    }, TIME_TRIGGER),
    // search product
    productOnSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.payload.productId = '';
      this.productSuggest.searchProduct = searchQuery;
      this.productSuggest.productOptions = [];
      this.productSuggest.filteredProductOptions = [
        ...this.productSuggest.productOptions,
      ];
      this.debounceProductInput();
    },
    productOnSelect(option) {
      this.payload.productId = option.item.id;
    },
    getProductList() {
      const text = this.productSuggest.searchProduct.trim();
      const param = {
        name: text,
        productType: [PRODUCT_TYPE.PRODUCT_COMPONENT],
      };
      const paramQuery = {
        params: param,
      };
      if (!text) {
        return;
      }

      ApiService.query('product/searchProductByProductType', paramQuery).then(
        (response) => {
          this.productSuggest.productOptions = [];
          response.data.data.forEach((item) => {
            item.productCode = item.productCode ? item.productCode : '';
            let suggestionName = item.productName;
            if (item.productCode !== '') {
              suggestionName = item.productName;
            }
            const product = {
              id: item.id,
              name: item.productName,
              code: item.productCode,
              suggestionName: suggestionName,
            };
            this.productSuggest.productOptions.push(product);
          });
          this.productSuggest.filteredProductOptions = [
            ...this.productSuggest.productOptions,
          ];
        },
      );
    },
    debounceProductInput: debounce(function () {
      this.getProductList();
    }, TIME_TRIGGER),
    // search customer
    customerOnSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }

      this.payload.customer.fullName = '';
      this.payload.customer.phoneNo = '';
      this.customerSuggest.disableNameField = true;
      this.customerSuggest.searchCustomer = searchQuery;
      this.customerSuggest.customerOptions = [];
      this.customerSuggest.filteredCustomerOptions = [
        ...this.customerSuggest.customerOptions,
      ];
      this.debounceCustomerInput();
    },
    customerOnSelect(option) {
      this.payload.customer.fullName = option.item.fullName;
      this.payload.customer.phoneNo = option.item.phoneNo;

      if (isValidPhoneNumber(option.item.phoneNo)) {
        this.customerSuggest.disableNameField = false;
      } else {
        this.customerSuggest.disableNameField = true;
      }
    },
    getListCustomer() {
      const text = this.customerSuggest.searchCustomer.trim();

      if (!text) {
        return;
      }

      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (response) => {
          let searchingAlreadyInResponse = false;
          this.customerSuggest.customerOptions = [];
          response.data.data.forEach((item) => {
            const suggestionName = item.fullName + ' - ' + item.phoneNo;
            if (text === item.phoneNo) {
              searchingAlreadyInResponse = true;
            }

            const customer = {
              id: item.id,
              fullName: item.fullName,
              phoneNo: item.phoneNo,
              priority: item.phoneNo,
              suggestionName: suggestionName,
            };
            this.customerSuggest.customerOptions.push(customer);
          });
          if (!searchingAlreadyInResponse) {
            const suggestionName = 'Thêm khách hàng mới - ' + text;
            this.customerSuggest.customerOptions.push({
              id: null,
              fullName: '',
              phoneNo: text,
              priority: text,
              suggestionName: suggestionName,
            });
          }
          this.customerSuggest.filteredCustomerOptions = [
            ...this.customerSuggest.customerOptions,
          ];
        },
      );
    },
    debounceCustomerInput: debounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    // filter stores
    fetchStore: async function () {
      this.optionsStore[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
        });
        this.storeFilteredOptions = [...this.optionsStore[0].data];
      });
    },
    onSelected(option) {
      this.payload.storeId = option.item.id;
      this.storeName = option.item.name;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }

      this.storeName = text;
      this.payload.storeId = null;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
      });

      this.storeFilteredOptions = [...filteredData];
    },
    // on submit
    async onSubmit() {
      this.isLoading = true;
      // reset validate
      this.numOfPayLoadProperty = 0;
      this.numOfValidField = 0;

      const {
        finalBuyingPrice: maskedFinalBuyingPrice,
        productBuyingPrice: maskedProductBuyingPrice,
      } = this.payload;
      this.payload.finalBuyingPrice = unMaskPrice(maskedFinalBuyingPrice);
      this.payload.productBuyingPrice = unMaskPrice(maskedProductBuyingPrice);

      const unnecessaryKey = ['note'];
      for (const key in this.payload) {
        this.numOfPayLoadProperty++;

        if (unnecessaryKey.includes(key)) this.numOfValidField++;

        if (key === 'customer') {
          const { fullName, phoneNo } = this.payload.customer;

          this.missing.customer.phoneNo =
            !phoneNo || !isValidPhoneNumber(phoneNo);
          this.missing.customer.fullName = !fullName;

          const { fullName: isMissingName, phoneNo: isMissingPhoneNo } =
            this.missing.customer;
          if (!isMissingName && !isMissingPhoneNo) this.numOfValidField++;
          continue;
        }

        if (this.payload[key]) {
          this.missing[key] = false;
          this.numOfValidField++;
        } else {
          this.missing[key] = true;
        }
      }

      if (this.numOfPayLoadProperty !== this.numOfValidField) {
        makeToastFaile('Vui lòng điền đầy đủ thông tin vào những ô bắt buộc!');
        this.isLoading = false;
        return;
      }

      await ApiService.post('tradein/mobile', this.payload)
        .then((response) => {
          makeToastSuccess(response.data.message);
          this.newTradeInId = response.data.data.id;
          this.$router.push('/trade-in');
        })
        .catch((err) => {
          const message =
            err.response.data.message || 'Tạo phiếu thu cũ thất bại';
          makeToastFaile(message);
        });

      this.isLoading = false;
    },
    //
  },
};
</script>

<style lang="scss">
.update-trade-in {
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .label-input {
    font-weight: 500;
  }
}
</style>

<style  scoped>
.required-control label::after {
  margin-left: 5px;
}
.missing-mess {
  margin-bottom: -0.5rem;
}
</style>